import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonLoading,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Error } from "../../data/error";

import AuthorizationService from "../../services/AuthorizationService";


const authorizationService = new AuthorizationService()

const LoginPhone: React.FC = () => {
    const history = useHistory()

    const [presentAlert] = useIonAlert()

    const [presentLoading, dismissLoading] = useIonLoading()

    const [phone, setPhone] = useState<number | undefined>()

    const submit = async () => {
        await presentLoading({
            message: 'Processing..'
        })

        if (phone !== undefined) {
            const response = await authorizationService.phone(phone)

            if (response.status === 200) {
                history.push(`/code/phone/${phone}`)
            }
            else {
                const error = response.data as Error

                await presentAlert({
                    header: 'An error occurred',
                    message: error.detail,
                    buttons: ['Close'],
                })
            }
        }

        await dismissLoading()
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text="Back" />
                    </IonButtons>
                    <IonTitle>Telephone number</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonText>
                    <p className="ion-padding"> Enter your telephone number that you provided us. We'll send you a 6 digits code to confirm you email.</p>
                </IonText>

                <IonItem lines="full" class="ion-margin-top">
                    <IonLabel position="stacked">Telephone number</IonLabel>
                    <IonInput placeholder="Type telephone number.." type="tel" value={phone} onIonChange={(e) => setPhone(Number(e.target.value))}></IonInput>
                </IonItem>

                <IonButton class="ion-margin-top ion-margin-horizontal" disabled={!phone} color="primary" expand="block" onClick={() => submit()}>Send</IonButton>
            </IonContent>
        </IonPage>
    )
}

export default LoginPhone