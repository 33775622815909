import "./theme/custom.css";
import "./theme/variables.css";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";

import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";

import { AppContext } from "./context/AppContext";
import { Measurement } from "./data/measurement";
import CodeEmail from "./pages/login/CodeEmail";
import CodePhone from "./pages/login/CodePhone";
import Login from "./pages/login/Login";
import LoginEmail from "./pages/login/LoginEmail";
import LoginPhone from "./pages/login/LoginPhone";
import MeasurementSlidesView from "./pages/measurements/MeasurementSlidesView";
import MeasurementView from "./pages/measurements/MeasurementView";
import SummaryView from "./pages/Summary/SummaryView";

setupIonicReact()

const App: React.FC = () => {
  const [token, setToken] = useState<string>("")
  const [gender, setGender] = useState<string>("")
  const [height, setHeight] = useState<number | undefined>(undefined)
  const [measurements, setMeasurements] = useState<Measurement[]>([])

  return (
    <AppContext.Provider value={{ token, gender, height, measurements, setToken, setGender, setHeight, setMeasurements }}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/" exact={true}>
              <Redirect to="/login" />
            </Route>

            <Route path="/login" component={Login} />
            <Route path="/email" component={LoginEmail} />
            <Route path="/phone" component={LoginPhone} />
            <Route path="/code/email/:email" component={CodeEmail} />
            <Route path="/code/phone/:phone" component={CodePhone} />

            <Route path="/measurements" component={MeasurementView} />
            <Route path="/measurements-details" component={MeasurementSlidesView} />

            <Route path="/confirm" component={SummaryView} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </AppContext.Provider>
  )
}

export default App;
