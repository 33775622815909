import { BaseService } from "./BaseService";
import { Measurement } from "../data/measurement"
import { CapacitorHttp, HttpResponse } from "@capacitor/core";

export default class MeasurementService extends BaseService {
    constructor() {
        super("/sizes")
    }

    async gender(token: string): Promise<HttpResponse> {
        const options = {
            url: this.base + '/gender/',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        return await CapacitorHttp.get(options)
    }

    async units(token: string): Promise<HttpResponse> {
        const options = {
            url: this.base + '/unit/',
            headers: {
                "Accept": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        return await CapacitorHttp.get(options)
    }

    async video(token: string, id: number, gender: string): Promise<HttpResponse> {
        const options = {
            url: this.base + `/unit/${id}/video/${gender}/`,
            headers: {
                "Content-Type": "video/mp4",
                "Authorization": `Token ${token}`
            }
        };

        return await CapacitorHttp.get(options)
    }

    async send(token: string, height: number, gender: string, measurements: Measurement[]): Promise<HttpResponse> {
        const options = {
            url: this.base + "/mesurements/",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
            data: JSON.stringify(
                {
                    "height": height,
                    "gender": gender,
                    "unit_values": measurements
                })
        };

        return await CapacitorHttp.post(options)
    }
}