import { BaseService } from "./BaseService";
import { CapacitorHttp, HttpResponse } from '@capacitor/core';

export default class AuthorizationService extends BaseService {
    constructor() {
        super("/verification")
    }

    async phone(number: number): Promise<HttpResponse> {
        const options = {
            url: this.base + '/phone/',
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify(
                {
                    phone: number
                }
            ),
        };

        return await CapacitorHttp.post(options);
    }

    async email(email: string): Promise<HttpResponse> {
        const options = {
            url: this.base + '/email/',
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify(
                {
                    email: email
                }
            ),
        };

        return await CapacitorHttp.post(options);
    }

    async verifyPhone(phone: number, code: string): Promise<HttpResponse> {
        const options = {
            url: this.base + '/phone/verify/',
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify(
                {
                    phone: phone,
                    verificationcode: code
                }
            ),
        };

        return await CapacitorHttp.post(options);
    }

    async verifyEmail(email: string, code: string): Promise<HttpResponse> {
        const options = {
            url: this.base + '/email/verify/',
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify(
                {
                    email: email,
                    verificationcode: code
                }
            ),
        };

        return await CapacitorHttp.post(options);
    }
}