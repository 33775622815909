import { IonButton, IonInput, IonItem, IonLabel, IonNote, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { Size } from "../../data/sizes";

interface Props {
    size: Size,
    gender: string,
    index: number,
    currentValue: number | undefined,
    update: (id: number, value: number) => void
}

const UnitView: React.FC<Props> = ({ size, gender, index, currentValue, update }: { size: Size, gender: string, index: number, currentValue: number | undefined, update: (id: number, value: number, back?: boolean) => void }) => {
    const [value, setValue] = useState<number | undefined>(currentValue)

    const videoRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        let videoUrl = `${process.env.REACT_APP_API_BASE_URL}/api/sizes/unit/${size.id}/video/${gender}/`

        videoRef.current!.autoplay = true
        videoRef.current!.playsInline = true
        videoRef.current!.muted = true
        videoRef.current!.loop = true
        videoRef.current!.controls = false
        videoRef.current!.src = videoUrl
        videoRef.current!.load()
    }, [size.id, gender])

    const submit = (back: boolean = false) => {
        update(size.id, value!, back)
    }

    return (
        <>
            <video ref={videoRef} width="100%" />

            <IonItem lines="full" >
                <IonLabel>{size.name ?? ""}</IonLabel>

                {size.id === 15 &&
                    <IonNote>Measure in cm</IonNote>
                }

                {size.id !== 15 &&
                    <IonNote>Measure in {size.unit ?? ""}</IonNote>
                }
            </IonItem>

            {size.description &&
                <IonItem lines="full">
                    <IonText class="ion-padding-vertical">{size.description ?? ""}</IonText>
                </IonItem>
            }

            {size.id === 15 &&
                <IonItem lines="full" class="ion-margin-bottom">
                    <IonSelect placeholder="Choose measurement.." onIonChange={(e) => setValue(Number(e.target.value))}>
                        <IonSelectOption value="228">22,8 cm</IonSelectOption>
                        <IonSelectOption value="231">23,1 cm</IonSelectOption>
                        <IonSelectOption value="235">23,5 cm</IonSelectOption>
                        <IonSelectOption value="238">23,8 cm</IonSelectOption>
                        <IonSelectOption value="241">24,1 cm</IonSelectOption>
                        <IonSelectOption value="245">24,5 cm</IonSelectOption>
                        <IonSelectOption value="248">24,8 cm</IonSelectOption>
                        <IonSelectOption value="251">25,1 cm</IonSelectOption>
                        <IonSelectOption value="254">25,4 cm</IonSelectOption>
                        <IonSelectOption value="257">25,7 cm</IonSelectOption>
                        <IonSelectOption value="260">26 cm</IonSelectOption>
                        <IonSelectOption value="267">26,7 cm</IonSelectOption>
                        <IonSelectOption value="273">27,3 cm</IonSelectOption>
                        <IonSelectOption value="279">27,9 cm</IonSelectOption>
                        <IonSelectOption value="286">28,6 cm</IonSelectOption>
                        <IonSelectOption value="292">29,2 cm</IonSelectOption>
                    </IonSelect>
                </IonItem>
            }

            {size.id !== 15 &&
                <IonItem lines="full" class="ion-margin-bottom">
                    <IonInput placeholder="Type measurement.." type="number" value={value} onIonChange={(e) => setValue(Number(e.target.value))}></IonInput>
                </IonItem>
            }

            <IonButton className="ion-margin" color="primary" disabled={value === undefined} expand="block" onClick={() => submit()}>Next</IonButton>

            {index > 0 &&
                <IonButton className="ion-margin" color="primary" expand="block" onClick={() => submit(true)}>Previous</IonButton>
            }
        </>
    )
}

export default UnitView
