import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonLoading,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Error } from "../../data/error";

import AuthorizationService from "../../services/AuthorizationService";

const authorizationService = new AuthorizationService()

const LoginEmail: React.FC = () => {
    const history = useHistory()

    const [presentAlert] = useIonAlert()

    const [presentLoading, dismissLoading] = useIonLoading()

    const [email, setEmail] = useState<string>("")

    const submit = async () => {
        await presentLoading({
            message: 'Processing..'
        })

        const response = await authorizationService.email(email)

        if (response.status === 200) {
            history.push(`/code/email/${email}`)
        }
        else {
            const error = await response.data as Error

            await presentAlert({
                header: 'An error occurred',
                message: error.detail,
                buttons: ['Close'],
            })
        }

        await dismissLoading()
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text="Back" />
                    </IonButtons>
                    <IonTitle>Email</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonText>
                    <p className="ion-padding"> Enter your email address that you provided us. We'll send you a 6 digits code to confirm you email.</p>
                </IonText>

                <IonItem lines="full">
                    <IonLabel position="stacked">Email</IonLabel>
                    <IonInput placeholder="Type email.." type="email" value={email} onIonChange={(e) => setEmail(e.target.value?.toString() ?? "")}></IonInput>
                </IonItem>

                <IonButton class="ion-margin-top ion-margin-horizontal" disabled={!email} color="primary" expand="block" onClick={() => submit()}>Send</IonButton>
            </IonContent>
        </IonPage>
    )
}

export default LoginEmail