import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonLoading,
} from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";

import { useSettings } from "../../context/AppContext";
import { Error } from "../../data/error";
import { TokenResponse } from "../../data/token";
import AuthorizationService from "../../services/AuthorizationService";

const authorizationService = new AuthorizationService()

interface CodeEmailProps extends RouteComponentProps<{ email: string }> { }

const CodeEmail: React.FC<CodeEmailProps> = ({ match }) => {
    const history = useHistory()

    const [presentAlert] = useIonAlert()

    const [presentLoading, dismissLoading] = useIonLoading()

    const [code, setCode] = useState<string>("")

    const { setToken, setMeasurements } = useSettings()

    const submit = async () => {
        await presentLoading({
            message: 'Processing..'
        })

        const response = await authorizationService.verifyEmail(match.params.email, code)

        if (response.status === 200) {
            const body = response.data as TokenResponse

            setToken(body.token)
            setMeasurements([])

            history.replace("/measurements")
        }
        else {
            const error = response.data as Error

            await presentAlert({
                header: 'An error occurred',
                message: error.detail,
                buttons: ['Close'],
            })
        }

        await dismissLoading()
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text="Back" />
                    </IonButtons>
                    <IonTitle>Verify</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonCard class="ion-margin-bottom">
                    <IonCardContent>
                        <IonText>A 6-digit code has been sent to your email. Please type and confirm below.</IonText>

                        <hr />

                        <IonText>It can take up to 5 minutes before the code arrive. If still not arrived, please check your spam folder or try again.</IonText>
                    </IonCardContent>
                </IonCard>

                <IonItem lines="full" class="ion-margin-bottom">
                    <IonLabel position="stacked">Code</IonLabel>
                    <IonInput placeholder="Type 6-digit code.." type="text" value={code} onIonChange={(e) => setCode(String(e.target.value))}></IonInput>
                </IonItem>

                <IonButton class="ion-margin-top ion-margin-horizontal" color="primary" expand="block" onClick={() => submit()}>Confirm</IonButton>
            </IonContent>
        </IonPage>
    )
}

export default CodeEmail