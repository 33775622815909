import { createContext, useContext } from "react"
import { Measurement } from "../data/measurement"

export type AppContextTypes = {
    token: string,
    gender: string,
    height: number | undefined,
    measurements: Measurement[]

    setToken: (token: string) => void
    setGender: (gender: string) => void
    setHeight: (height: number | undefined) => void
    setMeasurements: (measurements: Measurement[]) => void
}

export const ContextDefaultValues: AppContextTypes = {
    token: "",
    gender: "",
    height: undefined,
    measurements: [],
    setToken: () => { },
    setGender: () => { },
    setHeight: () => { },
    setMeasurements: () => { }
}

export const AppContext = createContext<AppContextTypes>(ContextDefaultValues);

export const useSettings = () => useContext(AppContext);