import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonAlert,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { useSettings } from "../../context/AppContext";
import { Error } from "../../data/error";
import { Size } from "../../data/sizes";
import MeasurementService from "../../services/MeasurementService";

const measurementService = new MeasurementService()

const SummaryView: React.FC = () => {
    const [sizes, setSizes] = useState<Size[]>([])

    const [presentAlert] = useIonAlert()

    const history = useHistory()

    const { token, gender, height, measurements } = useSettings()

    useEffect(() => {
        const load = async () => {
            const response = await measurementService.units(token)

            const body = response.data as Size[]

            setSizes(body)
        }

        load()
    }, [token])

    const submit = async () => {
        const response = await measurementService.send(token, height!, gender, measurements)

        if (response.status === 200) {
            await presentAlert({
                header: 'Success!',
                message: 'Your meassurements has been sent',
                buttons: ['Close'],
            })

            history.replace("/login")
        }
        else {
            const error = await response.data as Error

            await presentAlert({
                header: 'An error occurred',
                message: error.detail,
                buttons: ['OK'],
            })
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Overview</IonTitle>

                    <IonButtons slot="start">
                        <IonBackButton text={"Back"} defaultHref="/measurements" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonContent>
                    <IonList class="ion-margin-bottom" lines="full">
                        <IonItem>
                            <IonLabel slot="start">Sex</IonLabel>

                            <IonLabel slot="end">
                                {gender}
                            </IonLabel>
                        </IonItem>

                        <IonItem>
                            <IonLabel slot="start">Height</IonLabel>

                            <IonLabel slot="end">
                                {height}
                            </IonLabel>

                            <IonLabel slot="end">
                                <p>cm</p>
                            </IonLabel>
                        </IonItem>

                        {measurements && measurements.map((measurement) => {
                            return (
                                <IonItem key={measurement.unitId}>
                                    <IonLabel slot="start">{sizes.find(s => s.id === measurement.unitId)?.name}</IonLabel>

                                    <IonLabel slot="end">
                                        {measurement.value}
                                    </IonLabel>

                                    <IonLabel slot="end">
                                        <p>{sizes.find(s => s.id === measurement.unitId)?.unit}</p>
                                    </IonLabel>
                                </IonItem>
                            )
                        })}
                    </IonList>

                    <IonButton className="ion-margin" color="primary" expand="block" onClick={() => submit()}>Confirm</IonButton>
                </IonContent>
            </IonContent>
        </IonPage>
    )
}

export default SummaryView