import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Route } from "react-router";

import LoginEmail from "./LoginEmail";
import LoginPhone from "./LoginPhone";

const Login: React.FC = () => {
  const [logo, setLogo] = useState<string>("/assets/logo.png");

  useEffect(() => {
    const handleChange = (mediaQuery: MediaQueryListEvent) => {
      if (mediaQuery.matches) {
        setLogo("/assets/logo_dark.png");
      } else {
        setLogo("/assets/logo.png");
      }
    };

    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

    if (prefersDark.matches) {
      setLogo("/assets/logo_dark.png");
    } else {
      setLogo("/assets/logo.png");
    }

    prefersDark.addEventListener("change", (mediaQuery) =>
      handleChange(mediaQuery)
    );

    return () => {
      prefersDark.removeEventListener("change", handleChange);
    };
  }, []);

  return (
    <IonPage>
      <IonRouterOutlet>
        <Route path="/login/email" component={LoginEmail} />
        <Route path="/phone" component={LoginPhone} />
      </IonRouterOutlet>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Back" />
          </IonButtons>
          <IonTitle>Sizeguide</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid class="ion-margin-vertical">
          <IonRow>
            <IonCol size="2"></IonCol>
            <IonCol>
              <img
                width={"100%"}
                className="ion-padding-horizontal ion-margin-top"
                alt="Dybdahl Erhvervstøj logo"
                src={logo}
              />
            </IonCol>
            <IonCol size="2"></IonCol>
          </IonRow>
        </IonGrid>

        <IonCard>
          <IonCardContent>
            <IonText>
              Sizeguide provides a full guide for measuring and sending size
              information to Dybdahl Erhvervstøj for determining your clothing
              sizes. Use your phone number or email address to get started.
            </IonText>

            <IonButton
              routerLink="/phone"
              color="primary"
              expand="block"
              className="ion-margin-top"
            >
              Login with phone number
            </IonButton>

            <h2 className="ion-text-center could-not-login">
              Could not login with phone number?
            </h2>

            <IonButton
              routerLink="/email"
              fill="clear"
              color="primary"
              expand="block"
              className="ion-margin-top"
            >
              Login with email
            </IonButton>
          </IonCardContent>
        </IonCard>

        <IonText color="medium" className="ion-text-center">
          <p>Sizeguide: Dybdahl Erhvervstøj</p>
          <p>Version 1.0</p>
        </IonText>
      </IonContent>
    </IonPage>
  );
};

export default Login;
