import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonAlert } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { useSettings } from "../../context/AppContext";
import { Error } from "../../data/error";
import { Size } from "../../data/sizes";
import MeasurementService from "../../services/MeasurementService";
import UnitView from "./UnitView";

const measurementService = new MeasurementService()

const MeasurementSlidesView: React.FC = () => {
    const [sizes, setSizes] = useState<Size[]>([])
    const [selectedSize, setSelectedSize] = useState<Size>()

    const { token, gender, measurements, setMeasurements } = useSettings()

    const [presentAlert] = useIonAlert()

    const history = useHistory()

    useEffect(() => {
        const load = async () => {
            const response = await measurementService.units(token)

            if (response.status === 401) {
                const error = response.data as Error

                await presentAlert({
                    header: 'An error occurred',
                    message: error.detail,
                    buttons: ['Close'],
                })

                history.replace("/login")
            }
            else {
                const body = response.data as Size[]

                let sorted = body.sort((n1, n2) => n1.ordering - n2.ordering);

                setSizes(sorted)
                setSelectedSize(sorted[0])
            }
        }

        load()
    }, [setMeasurements, token, history, presentAlert])

    const update = (id: number, value: number, back: Boolean = false) => {
        if (measurements.find(m => m.unitId === id)) {
            const newState = measurements.map(measurement => {
                if (measurement.unitId === id) {
                    return { ...measurement, value: value };
                }

                return measurement;
            });

            setMeasurements(newState)

        }
        else {
            setMeasurements([...measurements, { unitId: id, value: value }])
        }

        if (back && sizes.indexOf(selectedSize!) > 0) {
            setSelectedSize(sizes[sizes.indexOf(selectedSize!) - 1])
        }
        else {
            if ((sizes.length - 1) === sizes.indexOf(selectedSize!)) {
                history.replace("/confirm")
            }
            else {
                setSelectedSize(sizes[sizes.indexOf(selectedSize!) + 1])
            }
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Sizes and meassurements</IonTitle>

                    <IonButtons slot="start">
                        <IonBackButton text={"Back"} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                {selectedSize &&
                    <UnitView key={selectedSize.id} size={selectedSize} index={sizes.indexOf(selectedSize)} currentValue={measurements.find(m => m.unitId === selectedSize.id)?.value} gender={gender} update={update} />
                }
            </IonContent>
        </IonPage>
    )
}

export default MeasurementSlidesView