import {
    IonButton,
    IonCard,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    useIonAlert,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { useSettings } from "../../context/AppContext";
import { Error } from "../../data/error";
import MeasurementService from "../../services/MeasurementService";

const measurementService = new MeasurementService()

const MeasurementView: React.FC = () => {
    const { token, gender, height, setGender, setHeight } = useSettings()

    const [genders, setGenders] = useState<string[]>([])

    const [presentAlert] = useIonAlert()

    const history = useHistory()

    useEffect(() => {
        const load = async () => {
            const response = await measurementService.gender(token)

            if (response.status === 401) {
                const error = response.data as Error

                await presentAlert({
                    header: 'An error occurred',
                    message: error.detail,
                    buttons: ['Close'],
                })

                history.replace("/login")
            }
            else {
                const body = response.data as string[]

                setGenders(body)
            }
        }

        load()
    }, [token, history, presentAlert])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>General information</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>

                <IonList>
                    <IonCard>
                        <img width={"100%"} src="/assets/measurement-info.png" alt="Billede af måle information" />
                    </IonCard>

                    <IonItem class="ion-margin-bottom">
                        <IonLabel position="stacked">Sex</IonLabel>

                        {genders &&
                            <IonSelect interface="action-sheet" placeholder="Choose sex" onIonChange={e => setGender(e.detail.value)}>
                                {genders.map((gender) => {
                                    return (
                                        <IonSelectOption key={gender} value={gender}>{gender}</IonSelectOption>
                                    )
                                })}
                            </IonSelect>
                        }
                    </IonItem>

                    <IonItem class="ion-margin-bottom">
                        <IonLabel position="stacked">Height (cm)</IonLabel>
                        <IonInput placeholder="Type height in cm.." type="number" value={height} onIonChange={(e) => setHeight(Number(e.target.value))}></IonInput>
                    </IonItem>

                    <IonButton disabled={(gender === undefined) || (height === undefined || (height === Number(0) || height > 250))} class="ion-margin ion-margin-top" routerLink="/measurements-details" routerDirection="forward" color="primary" expand="block">Next</IonButton>
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default MeasurementView